/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

.Meet {
    font-family: 'Roboto' sans-serif;
}
    
    .Meet div:nth-child(1) p {
        font-size: 55px;
        font-weight: 400;
        color: #012A5E;
        line-height: 0.75;
        margin-left: 35px;
        padding: 25px;
        }
    .Meet div span {
        font-size: 32px;
        font-weight: bold;
        color: #012A5E;
        }
    
    .Meet div p:nth-child(3) {
        font-size: 30px;
        font-weight: bold;
        color: #012A5E;
        
    }
    .Meet p:nth-child(1) {
        font-size: 50px;
        color: #012A5E;
      
    }
    
    .Meet div:nth-child(3) h5{
        font-size: 22px;
        line-height: 1.2;
        color: #444;
        text-align: justify;
        font-family: "Roboto", sans-serif;
        margin-left: 40px;
      padding: 30px 0 30px 0;
       
       
       
         
        
    }

  
    .Meet div:nth-child(2) {
        
        width: 3px  !important;
        background-color: #444444;
        margin-top: 20px;
        margin-bottom: 20px;

        
        }
    
    @media only screen and  (max-width: 1200px) {
        .Meet div:nth-child(1) {
        text-align: center;
        
            }
        .Meet div:nth-child(1) p {
            margin: auto;
            padding-top: 40px;
        }
        .Meet div:nth-child(3) p {
            margin: auto;
            padding-top: 30px;
            padding-bottom: 30px;
        }
            
    
        .Meet div:nth-child(3) h5 {
        text-align: center !important;
        margin: auto;
            
        }
    
        .Meet div:nth-child(2) {
            height: 3px;
            width: 260px !important;
            background-color: #444444;
            margin: auto; 
            
            margin-top: 30px;
            text-align: center;
    } 
    }
    @media only screen and (max-width: 400px) {
        .Meet div p {
            font-size: 32px !important;
            margin: auto;
            margin-top: 20px;
        }

 .Meet div span {
     font-size: 16px;
 }
 .Meet div:nth-child(3) p  {
     font-size: 18px !important;
     margin-bottom: 20px;
 }

    }
/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/


.Leadership {
    height: 700px;
    background-image: url('./Assets/leadership.jpg');
    background-position: center;
    background-size: cover;
    }
    
    .LeadershipText {
        height:90%;
        background-color: white;
        -webkit-transform: translate(20px, -40px);
            -ms-transform: translate(20px, -40px);
                transform: translate(20px, -40px);
        position: absolute;
        -webkit-box-shadow: rgba(80, 76, 76, 0.611) 2px 2px 2px 2px;
                box-shadow: rgba(80, 76, 76, 0.611) 2px 2px 2px 2px;
        font-family: 'Roboto', 'sans-serif';
        height: auto;
    }
    
    .LeadershipText p:nth-child(1) {
    font-size: 40px;
    color: #012a5e;
    font-weight: bold;
    margin-left: 40px;
    padding-top: 50px;
    }
    .LeadershipText p:nth-child(2) {
        font-size: 16px;
        color: #444;
        margin-left: 40px;
        padding-bottom: 60px;
    }
    
    .Technology {
        font-family: 'Roboto', 'sans-serif';
        color: #012a5e;
        font-size: 40px;
        font-weight: bold;
       text-align: center;
       margin-top: 40px;
        }
    
    .Stack {
        font-family: 'Roboto', 'sans-serif';
        color: #012a5e;
        text-align: center;
       }
    
    .Stack div:nth-child(1) img {
        width: 70%;
        margin-top: -35px;
        margin-left: 40px;
        }
    .Stack div:nth-child(2) img {
        width: 65%;
        margin-top: 12px;
        }
    .Stack div:nth-child(3) img {
        width: 75%;
        }
    
    .Wordpress {
        text-align: center;
    }
    
    .Wordpress div:nth-child(1) img {
        width: 80%;
        margin-left: 20px;
        }
    .Wordpress div:nth-child(2) img {
        width: 75%;
        margin-top: -10px;
        }
    .Wordpress div:nth-child(3) img {
        width: 80%;
        }
    .Process div:nth-child(1) {
    background: #0594d1; 
    color: white;
    font-family: 'Roboto', 'sans-serif';
    }
    
    .Process {
        width: 95%;
        margin: auto;
    }
    
    .Process div:nth-child(1) p:nth-child(1)
    
    {
    font-size: 45px;
    font-weight: bold;
    margin-left: 70px;
    padding-top: 40px;
    }
    
    .Process div:nth-child(1) p {
    margin-left: 70px;
    }
    
    .Process div:nth-child(1) p:nth-child(5){
    padding-bottom: 40px;
    }
    
    .Process div:nth-child(2) {
    
    font-family: 'Roboto', 'sans-serif';
    background-color: #ebeff2;
    }
    
    .Process div:nth-child(2) p:nth-child(1) {
    color: white;
    position: absolute;
    top:0;
    right: 10px;
    font-size: 30px;
    font-weight: bold;
    }
    .Process div:nth-child(2) p:nth-child(2) {
        color: #272b2f;
        font-size: 30px;
        font-weight: bold;
        margin-left: 50px;
        padding-top: 90px;
    } 
    .Process div:nth-child(2) p:nth-child(3) {
        margin-left: 50px;
        display: inline-block;
        width: 50%;
        margin-bottom: 50px;
    }

    .TextTypingDiscover {
     margin-left:100px;
    }

    @media only screen and (max-width: 992px) {

    .TextTypingDiscover {
        margin-left:0;
    }
    }
    @media only screen and (max-width: 400px) {
.HeaderNew {
    height: 350px;
}





.Overview p:nth-child(1) {
    font-size: 32px;
    margin-top: -200px;
   
}

.LeadershipText p:nth-child(1), .Technology, .Process p:nth-child(1) {
    font-size: 32px !important;
}

.Process div:nth-child(2) p:nth-child(1) {

    font-size: 26px !important;
}
.Process div:nth-child(2){
    text-align: center;
}

.Process div:nth-child(2) p:nth-child(2) {
    margin-left:0
}

.Process div:nth-child(2) p:nth-child(3) {
    margin-left: 0;
}

.Process div:nth-child(1) {
    
text-align: center;
    
}
.Process div:nth-child(1) p{
    margin-left: 0 !important;
}

.OverviewGeo P:nth-child(1) {
margin-top: -260px;

}
       
    }
    
    
    
    
    
/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

.Services {
    background: #eff2f2;
    height: 590px;
    }
      .BigIpad img {
          width: 80%;
          margin-top: 100px;
          margin-left: 10%;
      }
      .Ecommerce {
        color: #012A5E;
        font-family: 'Roboto','sans-serif';
    }
    .Ecommerce p:nth-child(1) {
        margin-top: 180px;
        font-size: 40px;
    }
    
    .Ecommerce p:nth-child(2) {
        font-size: 40px;
       margin-top: -10px;
        font-weight: bold;
        }
        .Ecommerce p:nth-child(3) {
            font-size: 40px;
            font-weight: bold;
            margin-top: -30px;
        }
        .Ecommerce p:nth-child(4) {
            color: black;
            font-size: 1.1rem;
        }
        .Ecommerce p:nth-child(5) {
            color: black;
            font-size: 1.1rem;
        }
        .CompleteEcommerce {
            text-align: center;
            color: #012A5E;
            font-family: 'Roboto','sans-serif';
                    }
            .CompleteEcommerce p:nth-child(1) {
                font-size: 36px;
                font-weight: bold; 
                margin-top: 70px;
                padding-top: 40px;
            }
            .CompleteEcommerce p:nth-child(2) {
                font-size: 36px;
                font-weight: bold; 
                margin-top: -20px;
            }
            .CompleteEcommerce p:nth-child(3) {
                color: black;
                font-size: 1.1rem;
                margin-top: 60px;
            }
            .CompleteEcommerce p:nth-child(4) {
                color: black;
                font-size: 1.1rem;
                margin-top: -10px;
                padding-bottom: 30px;
            }
        @media only screen and (max-width: 1200px) {
            .BigIpad img {
                width: 50%;
                display: block;
                margin: auto;
                margin-top: 90px;
            }
            .Ecommerce {
                text-align: center;
                margin-top: -140px;
            }
            .Services {
                height: 960px;
            }
        }
        @media only screen and (max-width: 960px) {
            
            .Services {
            height: 900px;
            }
        }
        @media only screen and (max-width: 760px) {
            
            .Services {
            height: 760px;
            }
        }
    
        @media only screen and (max-width: 560px) {
             .Services {
            height: 670px;
            }
        }
    .Strategies, SecondDeck {
        max-width: 95%;
        font-family: 'Roboto','sans-serif';
        text-align: center;
        margin: auto;
        
    }
    .Strategies div:nth-child(1) {
        background-color: #002a5e;
        color: white;
        height: 300px;
        }
    .Strategies div:nth-child(1) p:nth-child(1) {
        font-size: 44px;
        font-weight: bold;
        margin-top: 90px;
        }
    .Strategies div:nth-child(1) p:nth-child(2) {
        font-size: 30px;
        }
    .Strategies div:nth-child(2) {
            background-color: #414141;
            color: white;
            height: 300px;
            }   
        .Strategies div:nth-child(2) p:nth-child(1) {
            font-size: 44px;
            font-weight: bold;
            margin-top: 90px;
            }
        .Strategies div:nth-child(2) p:nth-child(2) {
            font-size: 30px;
            }
        .SecondDeck {
            min-height: 600px;
            max-width: 95%;
            margin: auto;
           }
    
        .Pic {
            width: 250px;
            }
        .Pic div img {
            height: 350px;
            background-position: center;
            background-size: cover;
            width: 100%;
           }
        .Offsite {
            background-color: #0199da;
            color: white;
            text-align: center;
            height: 250px; 
            width: 100%;  
        }
    
        .Offsite p:nth-child(1) {
            font-size: 44px;
            font-weight: bold;
            padding-top: 70px;
            }
    
        .Offsite p:nth-child(2) {
            font-size: 30px;
            }
    
        .SecondOne div{
            min-height: 300px;
           }
        .ThirdParty {
            background-color:#e6eff4;
            color: #002a5e;
            text-align: center;
        }
        .ThirdParty p:nth-child(1), .eStore p:nth-child(1), .Content p:nth-child(1),.Payment p:nth-child(1),
        .Marketing p:nth-child(1) {
            font-size: 44px;
            font-weight: bold;
            margin-top: 80px;
        }
    
        .ThirdParty p:nth-child(2), .eStore p:nth-child(2), .Content p:nth-child(2), 
        .Payment p:nth-child(2), .Marketing p:nth-child(2) {
            font-size: 30px;
        }
    
        .eStore {
            background-color: #0199da;
            color: white;
            text-align: center;
    }
    
        .Content {
           background-color:#002a5e;
            color: white;
            text-align: center; 
        }
        .Payment {
            background-color:#002a5e;
            text-align: center;
            color: white;    
        }
    
        .Marketing {
    background-color: #e6eff4;
            color: #002a5e;
            text-align: center;   
        }
    
        @media only screen and (max-width: 1200px){
            .Pic {
                display: none;;
            }
        }
    
        .What {
        height: 300px;
        text-align: center;
        }
        
        .What button {
        height: 50px;
            width: 350px;
            border-radius: 25px;
            margin-top: 125px;
            border: none;
            background-color: #EF323A;
            color: white;
            font-family: 'Roboto','sans-serif';
            font-weight: bold;
            font-size: 20px;
            -webkit-transition: all 0.4s;
            -o-transition: all 0.4s;
            transition: all 0.4s;
            
        }
        .What button:hover {
            background-color: #002a5e;
           }
    .Fuel {
            background-image: url('./Assets/laptop2.jpg');  
             background-size: cover;
             background-position: center;
             height: 500px;
               }
    .NoMatter {
        background-color: #0199da;
       color: white;
    }
    
    .NoMatter p:nth-child(1) {
        font-size: 44px;
        margin-left: 5%;
        padding-top: 90px;
    }
    
    .NoMatter p:nth-child(2) {
        font-size: 44px;
        margin-left: 5%;
        font-weight: bold;
    }
    .NoMatter p:nth-child(3) {
        font-size:18px;
        margin-left: 5%;
        line-height: 40px;
        margin-top: 40px;
        padding-bottom: 60px;
    }

    @media only screen and (max-width: 400px) {

        .Ecommerce p:nth-child(1) {
            font-size: 32px;
        }
    .Ecommerce p:nth-child(2) {
        font-size: 32px;
    }
    .Ecommerce p:nth-child(3){
        font-size: 32px;
        margin-top: -16px;
    }

    .CompleteEcommerce p:nth-child(1) {
        font-size: 30px !important;
        margin-top: -20px;
    }

    .CompleteEcommerce p:nth-child(2) {
        font-size: 30px !important;
    }

    .CompleteEcommerce p:nth-child(3) {
        margin-top: 0px;
    }
    .Strategies p {
        font-size: 32px !important;
    }
    
    .Strategies div:nth-child(2) p:nth-child(1) {
        font-size: 32px !important;
    }
    .What button, .WhatGeo button {
      width: 180px;
    }
    .NoMatter p:nth-child(1) {
        font-size: 32px !important;
    }
    .NoMatter p:nth-child(2) {
        font-size: 32px !important;
    }
    .EcommerceGeo p:nth-child(1) {
        font-size: 28px;
    }
    .EcommerceGeo p:nth-child(2) {
        font-size: 28px;
    }
    .EcommerceGeo p:nth-child(3){
        font-size: 28px;
    }

    .CompleteEcommerceGeo p:nth-child(1) {
    font-size: 28px !important;
    }
.StrategiesGeo div:nth-child(1) p:nth-child(1){
    font-size: 26px !important;
    margin-top: 100px;
}

.StrategiesGeo div:nth-child(1) p:nth-child(2){
    font-size: 26px !important;
}

.StrategiesGeo div:nth-child(2) p:nth-child(1){
    font-size: 26px !important;
    margin-top: 100px;
}

.StrategiesGeo div:nth-child(2) p:nth-child(2){
    font-size: 26px !important;
}
.StrategiesGeo div:nth-child(3) p:nth-child(1){
    font-size: 26px !important;
    margin-top: 100px;
}

.StrategiesGeo div:nth-child(3) p:nth-child(2){
    font-size: 26px !important;
}

.SecondOneGeo div p{
    font-size: 26px !important;
    
}
.SecondOneGeo div p:nth-child(1){
    margin-top: 100px;
}
.NoMatterGeo p:nth-child(1){

    font-size: 26px !important;
    
}

.NoMatterGeo p:nth-child(2) {
    font-size: 26px !important;
    
}

    }
    
    
    
           
        
    
    
    
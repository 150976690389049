/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

.Logos {

    height: 850px;
    background-color: #f7f7f7;
}
.WhyReact {
    text-align: center;
    font-family: 'Roboto' sans-serif;
    margin-top: 60px;
}

.WhyReact p:nth-child(1) {
    color: #012A5E;
    font-size: 50px;
   
    }
    .WhyReact p:nth-child(2){
        font-weight: 700;
        font-size: 40px;
        color: #012A5E;
        margin-top: -20px;
        
        }
 
.ReactLogo {
    
    text-align: center;
    
}

.FacebookLogo, .WhatsAppLogo,.InstagramLogo, .NetflixLogo, .YahooLogo, .NytimesLogo {
    text-align: center;
    margin-top: 60px;
}
.FacebookLogo, .WhatsAppLogo {
    transform: translate(-50px);
}
.InstagramLogo {
    transform: translate(50px);
}

.NetflixLogo, .NytimesLogo {
    transform: translate(50px);
}

.YahooLogo {
    transform: translate(-50px);
}

.CompanyLogos {
    margin-top: 80px;
}

.FacebookMini {
    display: block;
    margin: auto;
}
.InstagramMini {
    display: block;
    margin: auto;
}
.WhatsappMini {
    display: block;
    margin: auto;
}
.NytimesMini {
    display: block;
    margin: auto;
}
.NetflixMini{
    display: block;
    margin: auto;
}
.YahooMini {
    display: block;
    margin: auto;
} 
.CodeacademyMini {
    display: block;
    margin: auto;
} 
.DropboxMini{
    display: block;
    margin: auto;
}
.LogosLastMini {
    margin-top: 30px;
}


@media only screen and (max-width: 1200px) {
    
    .CompanyLogosMini {
        margin-top:20px;

    }
    .Logos {
        height: 500px;
    }
    }
@media only screen and (max-width:992px) {
  .Logos {
      height: 660px;
  }
}




@media only screen and (max-width:765px) {
    .WhyReact {
        margin-top: 20px;
        }

    .WhyReact>p {
       font-size: 33px !important;
    }
    .MovingCube {
margin-top: 60px;
}
    .CompanyLogosMini {
       -webkit-transform: translate(2%);
           -ms-transform: translate(2%);
               transform: translate(2%);
               margin-top: 0px;
       
    }
    .FacebookMini  {
        margin-top: 0px;
    }
    .InstagramMini {
        margin-top: 20px;
    }
    .WhatsappMini {
        margin-top: 20px;
    }

    .NytimesMini {
        margin-top: 20px;
    }
    .YahooMini {
        margin-top: 20px;
    }
    .CodeacademyMini {
        margin-top: 20px;
    }
    .DropboxMini {
        margin-top: 20px;
    }
 .Logos {
     height: 1000px;
 }
}

@media only screen and (max-width: 400px) {
    .WhyReact p:nth-child(1) {
        font-size: 24px !important;
        margin-top: 10px;
    }
    .WhyReact p:nth-child(2) {
        font-size: 24px !important;
    }
    .FacebookMini, .NetflixMini, .CodeacademyMini {
        width: 140px;
    }
   
    .InstagramMini, .WhatsappMini, .NytimesMini, .YahooMini, .DropboxMini {
        width: 60px;
    }

  .Logos {
      height: 720px;
  }
}


/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

.Footer {
    background-color:#012A5E;
    height: 280px;
    }
@media only screen and (min-width: 2200px) {
    .Footer {
        height: 340px;
    }
}
    
    .RedLine {
        background-color: #EF323A;
        height: 6px;
        width: 100%;
        }
    .WhiteLine {
        height: 200px;
        width: 3px;
        margin-top: 45px;
        margin-left: 36px;
        background-color: white;    
    }
    
    .Address {
       color: white;
       
    }
    .Address p:nth-child(1) {
        font-size: 40px;
        margin-left: 10%;
        margin-top: 34px;
        width: 100%;
        
    }
    .Address p:nth-child(2){
        font-size: 16px;
        margin-left: 10%;
        width: 100%;
        line-height: 0.5rem;
    }
    
    .Address p:nth-child(3){
        font-size: 16px;
        margin-left: 10%;
        line-height: 0.5rem;
    }
    .Address p:nth-child(4){
        font-size: 16px;
        margin-left: 10%;
        line-height: 0.5rem;
    }
    
    .Address p:nth-child(5){
        font-size: 16px;
        margin-left: 10%;
        line-height: 0.5rem;
    }
    
    .Know {
        color: white;
        font-size: 40px;
        margin-top: 40px;
        margin-left: 50px;
    }
    
    .ContactForm {
        font-family: 'Roboto', 'sans-serif';
    }
    .SendMessage {
        color: white;
        margin-left: 50px;
        font-weight: bold;
        min-width: 140px;
    }
    
    input {
        background-color: #012A5E;
        border: 1px solid white;
        border-top: none;
        border-left: none;
        border-right: none;
        }
    .Sending {
        color:white;
        background-color:#EF323A;
        border-width: 0;
        cursor: pointer;
        border-radius:16px;
        font-size: 16px;
        height: 34px;
        width: 100px;
        margin-top: 20px;
       
    }
    
    .SendingGeo {
        color:white;
        background-color:#EF323A;
        border-width: 0;
        cursor: pointer;
        border-radius:16px;
        font-size: 16px;
        height: 34px;
        width: 100px;
        margin-top: 20px;
    }
    
    .Sending:hover {
        background-color:white;
        color: #012A5E;
        -webkit-transition: all 0.4s;
        -o-transition: all 0.4s;
        transition: all 0.4s;
    }
    
    .SendingGeo:hover {
        background-color:white;
        color: #012A5E;
        -webkit-transition: all 0.4s;
        -o-transition: all 0.4s;
        transition: all 0.4s;
    }
    
    textarea {
        background-color: #012A5E;
    
        border: 1px solid white;
        border-top: none;
        border-left: none;
        border-right: none;
        color: white;
        height: 30px;
        margin-top: 30px;
        }
    textarea::-webkit-input-placeholder {
        color: white;
    }
    textarea::-moz-placeholder {
        color: white;
    }
    textarea:-ms-input-placeholder {
        color: white;
    }
    textarea::-ms-input-placeholder {
        color: white;
    }
    textarea::placeholder {
        color: white;
    }
    input::-webkit-input-placeholder {
        color: white;
    }
    input::-moz-placeholder {
        color: white;
    }
    input:-ms-input-placeholder {
        color: white;
    }
    input::-ms-input-placeholder {
        color: white;
    }
    input::placeholder {
        color: white;
    }
    input:focus {
        color: white
    }
    input[type=text] {
        
        color: white;
      }
      input[type=email] {
        color: white;
        background-color:#012A5E ;
      } 
      .Name, .Email {
        width: 100%;
        } 
      
      .Textarea {
          width: 100%;
          margin-top: 30px;
      }
    
      @media only screen and (max-width: 1200px)  {
    
        .Footer {
            height: 600px;
        }
    
        .Know, .SendMessage {
       text-align: center;
       margin-left: 0;
        }
        .Sending, .SendingGeo {
        display: block;
        margin:auto;
        margin-top: 20px;
        }
        .DivName {
            width: 60%;
            margin: auto;
            margin-top: 30px;
            }
        
        .DivTextarea {
            width: 60%;
            margin-top:30px;
            display: block;
            margin: auto;
             }
        .WhiteLine {
        display: none;
        }
      .Address {
          margin: auto;
          text-align: center;
          margin-top: -10px;
          }
    .Address p:nth-child(1) {
        margin-left: 0%;
    }
    
    .Address p:nth-child(2) {
        margin-left: 0%;
    }
    .Address p:nth-child(3) {
        margin-left: 0%;
    }
    .Address p:nth-child(4) {
        margin-left: 0%;
    }
    .Address p:nth-child(5) {
        margin-left: 0%;
    }
    .MovingCube {
        margin-top: 0;
    }
    }
    @font-face {
        font-family: "Mrg";
        src:url('/src/Fonts/bpg-mrgvlovani-webfont.ttf');
    }
    
    @font-face {
    
        font-family: 'Nateli';
        src:url('/src/Fonts/bpg-nateli-webfont.ttf') format('truetype')
    }

    @media only screen and (max-width: 400px) {

.Footer {
    height: 680px;
}

    }
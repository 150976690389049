
.MovingCube div:nth-child(1) {
   
    color: white;
    min-height: 360px;
    background-image: url('./Assets/MobileDark.png');
    background-position: center;
    background-size: cover;
    filter: grayscale(0.6);
}
.MovingCube p:nth-child(1) {
font-size: 45px;
font-family: 'Roboto';
font-weight: bold;
letter-spacing: 7px;
position: relative;
margin-top: 100px;
margin-left: 20px;

}

.MovingCube p:nth-child(2) {
    font-size: 22px;
    line-height:1.5;
    margin-left: 20px;
    font-family: "Roboto";
  }
.Moving {
    background-image: url('./Assets/MovingCube38.png');
    min-width: 360px;
    max-inline-size: 360px;
   animation: moving 8s infinite ease-in-out;
    }

    .MovingGeo {
        background-image: url('./Assets/MovingCube38Geo.png');
        min-width: 360px;
        max-inline-size: 360px;
       animation: movingGeo 8s infinite ease-in-out;
        }

.WebContainer::after{
    content: '';
    background-color: black;
    width: 100%;
    height: 0;
    bottom: 0;
    display: block;
    position: absolute;
    opacity: 0.02;
    transition: all 0.3s ease-in-out;
    }

    .WebContainer:hover.WebContainer::after {
        height: 100%;
       }
    .ConsultingContainer::after{
        content: '';
        background-color: black;
        width: 100%;
        height: 0;
        bottom: 0;
        display: block;
        position: absolute;
        opacity: 0.1;
        transition: all 0.3s ease-in-out;
        }
    
        .ConsultingContainer:hover.ConsultingContainer::after {
            height: 100%;
           }
    @keyframes moving {
0% {
background-position: top left;
}
25% {
background-position: top right;
}
50% {
background-position: bottom right;
    }
75% {
background-position: bottom left;
    }

}

@keyframes movingGeo {
        0% {
        background-position: top left;
        }
        25% {
        background-position: top right;
        }
        50% {
        background-position: bottom right;
            }
        75% {
        background-position: bottom left;
            }
        
            }
  

    .MovingCube> div:nth-child(3) {
       background-color:#EDF1F5;
       color:#012A5E;
       min-height: 360px;
       }   
       
@media only screen and (max-width:400px) {
    .MovingCube p:nth-child(1) {
        font-size: 20px;
        margin-top: 50px;;
    }
    .MovingCube p:nth-child(2) {
        font-size: 16px;
    }
    .MovingCube div:nth-child(1) {
        min-height: 200px;
    }
    .MovingCube div:nth-child(3) {
        min-height: 160px;
    }
    
}

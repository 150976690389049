/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

.All div:nth-child(1) {
    background-image: url(./Assets/compTyping.png);
    background-size: cover;
    background-position: center;
    min-height: 700px;
    }
 
 .GenInfo {
     background-color: #012A5E;
     font-weight: bold;
     font-family: 'Roboto', 'sans-serif';
     color: white;
     
 }
 .GenInfo a {
     color: white;
     text-decoration: none;
     font-size: 50px;
 }
 
 .GenInfo p:nth-child(1) {
 
     padding-top: 190px;
     
 }
 .GenInfo p:nth-child(3) {
     padding-bottom: 190px;
 }
 .GenInfo p {
 
     margin-left: 20px;
 }
 
 @media only screen and (max-width:992px) {
 
     .GenInfo {
         text-align: center;
         }
 
     .GenInfo a {
         font-size: 40px;
         }
 }
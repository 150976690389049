html { scroll-behavior: smooth; } 

.Typing {
    height: 550px;
    min-width: 100%;
   background: url('./Assets/DarkenedHeader.jpg');
   background-size: 100%;
   
  }
  .RedBar {
    height: 4px;
    width: 107px;
    background-color: #EF323A;
    position: relative;
    top:260px;
    left:100px;}

    @media only screen and (max-width: 992px) {
        .RedBarCol {
         display: none;
        }

        .TextTyping {
top:0
        }
      }

    .TextTyping {
color: white;
        font-size: 38px;
        position: relative;
        top:190px;
        left: 20px;
        
        font-weight: normal;
        line-height: 40px;
        font-family: 'Roboto', 'sans-serif';
    }
    
    .TextTyping p:nth-child(2) {

        font-weight: bold;
        font-size: 44px;
    }

  
    

    .Oval  {
        height: 6px !important;
        margin-left: -16px;
        position: relative;
        animation: Oscilation 3s infinite;
       }

    @keyframes Oscilation {
        0% {
        top: -8px;
        }
        50% {
        top:8px;
        }
        100% {
        top:-8px;
        }
    }
@media only screen and (max-width:1000px) {

    .TextTyping {
        text-align: center;
        left: 0;
        font-size: 3.9vw;
    }
    .TextTyping p:nth-child(2){
        font-size: 4.3vw;
    }
    .Oval {
        margin:auto;
        transform: translate(-15.5px);
    }
}

.HeaderNew{
    background-image: url('./Assets/HandTyping.png');
    background-size: cover;
    background-position: center;
}
.Overview {
    margin-top: 412px;
   text-align: center;
}

.Overview p:nth-child(1) {
    color: #012a5e;
    padding-top: 50px;
    font-size: 45px;
    font-family: 'Montserrat', 'sans-serif';
    font-weight: bold;
}

.Overview p:nth-child(2) {
    color: #444;
    font-size: 16px;
    display: inline-block;
    width: 70%;
    padding-bottom: 50px;
}

@media only screen and (max-width: 480px) {

    .Typing {
        height: 400px;
    }
    .TextTyping, TextTypingGeo {
        top:110px !important;
    }
    .TextTypingGeo p {
        font-size: 2.5vw !important;
       
    }
    .TextTypingGeo p:nth-child(2) {
        font-size: 2.9vw !important;
    }

    
}


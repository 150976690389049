


.NavBar {
  z-index: 1;
  height: 70px;  
  width: 100%;
  position: absolute;
  margin-top: 0;
  font-family: 'Roboto','sans-serif';
  font-weight: bold;
  font-size: 20px;
  transition: all 0.5s;
  background: none;
  
  
    }




.Scrolled {
    position: fixed;
    background-color: white;
    animation: appair 0.7s;
    
  }
.Logo {
    height: 40px;
   
    
    }

  .Globe {
  height: 40px;
  
  }

  .Angle {
  
    margin-left: 10px;
  }

  .GlobeAngle {
    min-width: 120px;
  }

    .hvr-underline-from-left {
      display: inline-block;
      vertical-align:middle;
      -webkit-transform: perspective(1px) translateZ(0);
      transform: perspective(1px) translateZ(0);
      box-shadow: 0 0 1px rgba(0, 0, 0, 0);
      position: relative;
      overflow: hidden;
    }
    .hvr-underline-from-left:before {
      content: "";
      position: absolute;
      z-index: -1;
      left: 10px;
      right: 100%;
      bottom: 12px;
      background: #ffffff;
      height: 1px;
      -webkit-transition-property: right;
      transition-property: right;
      -webkit-transition-duration: 0.3s;
      transition-duration: 0.3s;
      -webkit-transition-timing-function: ease-out;
      transition-timing-function: ease-out;
    }
    .hvr-underline-from-left:hover:before, .hvr-underline-from-left:focus:before, .hvr-underline-from-left:active:before {
      right: 52%;
    }

    .hvr-underline-from-left-blue {
      display: inline-block;
      vertical-align:middle;
      -webkit-transform: perspective(1px) translateZ(0);
      transform: perspective(1px) translateZ(0);
      box-shadow: 0 0 1px rgba(0, 0, 0, 0);
      position: relative;
      overflow: hidden;
    }
    .hvr-underline-from-left-blue:before {
      content: "";
      position: absolute;
      z-index: -1;
      left: 10px;
      right: 100%;
      bottom: 12px;
      background: #012A5E;
      height: 1px;
      -webkit-transition-property: right;
      transition-property: right;
      -webkit-transition-duration: 0.3s;
      transition-duration: 0.3s;
      -webkit-transition-timing-function: ease-out;
      transition-timing-function: ease-out;
    }
    .hvr-underline-from-left-blue:hover:before, .hvr-underline-from-left-blue:focus:before, .hvr-underline-from-left:active:before {
      right: 52%;
    }
    .hvr-bounce-in {
      display: inline-block;
      vertical-align: middle;
      -webkit-transform: perspective(1px) translateZ(0);
      transform: perspective(1px) translateZ(0);
      box-shadow: 0 0 1px rgba(0, 0, 0, 0);
      -webkit-transition-duration: 0.5s;
      transition-duration: 0.5s;
    }
    .hvr-bounce-in:hover, .hvr-bounce-in:focus, .hvr-bounce-in:active {
      -webkit-transform: scale(1.2);
      transform: scale(1.2);
      -webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
      transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
    }
.StartProject {
  height: 40px;
  padding:0;
  margin-top: 5px;
 
  
}



.StartProject img {
  margin: 0;
}
.StartProject img:hover {
  cursor: pointer;
}

.Bars {
min-width: 65px;
margin-left: 4px;

}
.Bars img {
display: block;
margin-top: 7px;
  }
.Bars img:nth-child(2) {
  margin-left: 7px;
  transition: all 0.4s;
}
.Bars:hover.Bars img:nth-child(2) {
  margin-left: 0;
}

@media only screen and  (max-width: 1200px) {
.navbar-nav {
margin-left: 0;
}
.navbar-nav a:nth-child(1) {
max-width: 100px;
margin: auto;
  }
  .navbar-nav a:nth-child(2) {
    max-width: 100px;
    margin: auto;
    transform: translate(10px);
    }

      .navbar-nav a:nth-child(3) {
        margin: auto;
        
          }
          .navbar-nav a:nth-child(4) {
        margin: auto;
        transform: translate(10px);

              }

              .navbar-nav a:nth-child(5) {
                margin: auto;
                transform: translate(27px);

                  }     
                  .Angle {
                    display: none;
                  }     
    .navbar-collapse {
    background-color:grey;
    margin-top: 40px;
    border-radius: 16px;
    opacity: 0.94;
    }
  .hvr-underline-from-left {
    display: inline-block;
    vertical-align:middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    position: relative;
    overflow: hidden;
  }
  .hvr-underline-from-left:before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 0px;
    right: 100%;
    bottom: 8px;
    background: #ffffff;
    height: 1px;
    -webkit-transition-property: right;
    transition-property: right;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
  }
  .hvr-underline-from-left:hover:before, .hvr-underline-from-left:focus:before, .hvr-underline-from-left:active:before {
    right: 50%;
  }

  .hvr-underline-from-left-blue {
    display: inline-block;
    vertical-align:middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    position: relative;
    overflow: hidden;
  }
  .hvr-underline-from-left-blue:before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 0px;
    right: 100%;
    bottom: 8px;
    background: #012A5E;
    height: 1px;
    -webkit-transition-property: right;
    transition-property: right;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
  }
  .hvr-underline-from-left-blue:hover:before, .hvr-underline-from-left-blue:focus:before, .hvr-underline-from-left:active:before {
    right: 52%;
  }
  .hvr-bounce-in {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
  }
  .hvr-bounce-in:hover, .hvr-bounce-in:focus, .hvr-bounce-in:active {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    -webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
    transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
  }
.RedBar {
  display: none;
}

.navbar-toggler{
  border-width: 0;
}
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
    outline: none;
    box-shadow: none;
}
}

.extraClass {
pointer-events: auto !important;
min-width: 130px;
cursor: pointer;
}
.tooltiptext img{
width: 40px;
display: inline-block;
margin-right: 10px;
margin-left: 3px;

  }
  .Language {
    display: inline-block;
    padding-top: 5px;
   }
 
  @font-face {
    font-family: 'NinoCaps';
    src:url('./Fonts/bpg-nino-medium-caps-webfont.ttf') format('truetype')
  }
  @font-face {

    font-family: 'NinoBold';
    src: url('./Fonts/bpg-nino-mtavruli-bold-webfont.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Nateli';
    src:url('./Fonts//bpg-nateli-webfont.ttf') format('truetype')
  }

  @media only screen and (max-width: 1200px){

    .Logo {
      margin-left:20px;
     
    }
    .navbar-nav {
      text-align: center;
    }

    .navbar-nav a:nth-child(5) {
     transform: translate(-4px);
    }

  }

  @media only screen and (max-width: 400px) {

.Logo {

  margin: auto;
  margin-top: -10px;
  width: 120px;
  height: auto;
}
  }

  